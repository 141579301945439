/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Provider {
    PROJECT_44 = 'PROJECT_44',
    ROSE_ROCKET = 'ROSE_ROCKET',
    GLS_CANADA = 'GLS_CANADA',
    MANITOULIN = 'MANITOULIN',
    THREE_PL = 'THREE_PL',
    PUROLATOR = 'PUROLATOR',
    FEDEX = 'FEDEX',
    NATIONEX = 'NATIONEX',
    CANPAR = 'CANPAR',
    DAY_AND_ROSS = 'DAY_AND_ROSS',
    CANADA_POST = 'CANADA_POST',
    UPS = 'UPS',
    DHL = 'DHL',
    SPEEDY = 'SPEEDY',
    MIDlLAND = 'MIDlLAND',
    MORNEAU = 'MORNEAU',
}